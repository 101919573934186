* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('/src/font/IBMPlexMono-Bold.ttf');
}

body {
  background-color: #1E1E1E;
  color: #e6e6e6;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
}

input {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.text-disabled {
  color: #8f8f8f;
}

.touch-device .text-clickable,
.text-clickable:hover {
  text-decoration: underline;
}

/* past dialog */
.past-dialog {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  border: 1px solid gray;
  padding: 20px 20px 0 20px;
  background-color: #1E1E1E;
  color: #fff;
  border-radius: 15px;;
  width: 400px;
}

.past-list {
  margin-top: 10px;
}

.past-item {
  border-bottom: 1px solid gray;
  padding: 4px 0;
}

.past-dialog-footer {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background-color: #1E1E1E;
}

.past-dialog-button {
  margin-left: 10px;
  padding: 8px 15px;
  border: transparent;
  border-radius: 8px;
  cursor: pointer;
}

/* refactored */
/* Header */
header {
  padding: 10px 20px;
}

.links-wrapper {
  margin-left: auto;
}

.svg-icon {
  margin-left: 10px;
}

.svg-icon-disabled {
  opacity: 0.5;
}

/* /Header */
/* List */
.player-list {
  grid-template-columns: min-content 1fr;
  gap: 2px 5px;
}
/* /List */
/* Content */
main {
  min-height: 0;
}
/* /Content */
/* Menu */
.menu {
  padding: 20px;
  max-height: 100%;
}

.menu-content-center {
  max-width: 450px;
  margin: 0 auto;
}

.menu-caption {
  padding: 10px 0 4px 32px;
}

.menu-back-button {
  filter: invert(1);
  margin-right: 10px;
  width: 20px;
}
/* /Menu */
/* Editor */

.player-item-index {
  min-width: 18px;
}

.player-item, .player-item input {
  font-size: 13px;
}

.legend-wrapper {
  margin-left: 4px;
  margin-bottom: 10px;
}

.legend-wrapper-with-index {
  margin-left: 24px;
}

.legend-input {
  border: 1px solid #3a3a3a;
  min-height: 30px;
  padding: 5px 10px;
  border-radius: 20px;
  color: black;
}

.cursor-device .editor-input:focus:not(:disabled) + .editor-actions,
.cursor-device .player-item:hover .editor-input:not(:disabled) + .editor-actions {
  visibility: visible;
}

.cursor-device .editor-actions {
  visibility: hidden;
}

.touch-device .editor-input:disabled + .editor-actions {
  visibility: hidden;
}

.editor-input {
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  min-height: 30px;
  padding: 0 calc(var(--actions-count, 1) * 26px + 8px) 0 10px;
  color: black;
}

input:focus {
  outline: none;
}

.editor-action {
  margin-left: 10px;
}

.editor-actions {
  top: 8px;
  right: 8px;
}

.editor-area {
  padding: 10px calc(var(--actions-count, 1) * 26px + 8px) 10px calc(20px + 7px * var(--index-length));
  border: none;
  resize: none;
  outline: none;
  font-size: 16px;
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
  line-height: 18px;
  background-color: #262727;
  border-radius: 8px;
  color: #dadada;
  font-weight: 700;
}

.editor-area-indexes {
  top: 0;
  left: 0;
  line-height: 18px;
  z-index: 1;
  color: #1E1E1E;
  border-right: 1px solid #5c5c5c;
  padding: 9px 4px;
  font-size: 11px;
  min-width: 24px;
  background-color: #2f3030;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #dadada;
}

/*/ Editor */
/* Spinner */
.winner-field {
  text-align: center;
  padding: 0 20px 10px;
  font-size: 25px;
  font-family: "IBM Plex Mono", cursive, sans-serif;
  font-weight: bold;
  height: 45px;
  max-width: calc(100vw / 3 * 2 - 30px);
}

.spinner-wrapper {
  min-height: 0;
  min-width: 0;
}

.spinner {
  right: 10px;
  top: 50%;
}

.spinner-back {
  transform: rotate(var(--spinner-angle, 0));
}
.spinner-back,
.spinner-front {
  top: 0;
  left: 0;
}
/* /Spinner */
/* Keyboard */
.keyboard-opened {
  background-color: #222;
}

.keyboard-opened .winner-field {
  display: none;
}

.keyboard-opened .spinner {
  width: 50px;
  max-height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all 0.5s;
  pointer-events: none;
}
/* /Keyboard */

@media (orientation: portrait) {
  main {
    flex-direction: column;
  }
  .spinner {
    max-height: 50vh;
  }
  .menu {
    width: 100%;
  }
  .winner-field {
    max-width: 100%;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ffffff49;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
